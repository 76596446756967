import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import EventsList from '../components/Events/EventsList'

export default function Home({ data: { strapi: { events } }, location }) {

	return (
		<>
			<SEO title="Events" pathname={ location.pathname } />

			{ events &&
				<EventsList data={ events }/>
			}
		</>
	)
}

export const query = graphql`
	query eventsPageQuery {
		strapi {
			events(sort: "eventDateStart:ASC") {
				id
				eventDateStart
				eventDateEnd
				additionalEndDateInfo
				eventSummary
				eventLocation
				eventTitle
				eventSlug
				eventImage {
					id
					url
					imageFile {
						childImageSharp {
							fluid(maxWidth: 500, maxHeight: 500) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
				}
			}
		}
	}
`
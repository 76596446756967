import React from 'react'
import moment from 'moment'

import EventsListItem from './EventsListItem'
import ComponentAnimation from '../ComponentAnimation/ComponentAnimation'

export default ( props ) => {

	const { data } = props
	const upcomingEvents = []
	const previousEvents = []

	data.forEach( event => {
		const now = moment()
		// if the end date is before today then it has finished, so it is a previous Event
		if ( moment( event.eventDateEnd ).isBefore( now ) ){
			previousEvents.push( event )
		} else {
			upcomingEvents.push( event )
		} 
	})

	return (
		<section className="events-list wrapper">
			{ !!upcomingEvents.length &&
				<div className="events-list__container">
					<div className="events-list__header">Upcoming Events</div>
					{ upcomingEvents.map( event => (
						<EventsListItem data={ event } key={ event.id } />
					) ) }
				</div>
			}
			{ !!previousEvents.length &&
				<div className="events-list__container">
					<ComponentAnimation>
						<div className="events-list__header">Previous Events</div>
					</ComponentAnimation>
					{ previousEvents.map( event => (
						<EventsListItem data={ event } key={ event.id } />
					) ) }
				</div>
			}
		</section>
	)
}
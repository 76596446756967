import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import moment from 'moment'
import ReactMarkdown from 'react-markdown'
import ComponentAnimation from '../ComponentAnimation/ComponentAnimation'

export default ( props ) => {

	const { data } = props

	return (
		<div className="event-list-item">
			<ComponentAnimation>
				<div className="event-list-item__container">
					<div className="event-list-item__text-container">
						<div className="event-list-item__heading">{ data.eventTitle }</div>
				
						<div className="event-list-item__date-container">
							{ data.eventLocation && <span className="event-list-item__date">{ data.eventLocation } - </span> }
							<span className="event-list-item__date">{ moment( data.eventDateStart ).format( "DD.MM.YY" ) }</span>
							{ data.eventDateEnd !== data.eventDateStart &&  
								<span className="event-list-item__date">- { moment( data.eventDateEnd ).format( "DD.MM.YY" ) }</span>
							}
							{ data.eventDateEnd === data.eventDateStart && data.additionalEndDateInfo && <span className="event-list-item__date">{ data.additionalEndDateInfo }</span> }
						</div>
						<ReactMarkdown
								className="event-list-item__summary"
								escapeHtml={ false }
								source={ data.eventSummary }
							/>
						<Link to={ `/events/${ data.eventSlug }/` } className="event-list-item__link">Read More</Link>
					</div>

					<div className="event-list-item__image-container">
						<Img fluid={{ ...data.eventImage.imageFile.childImageSharp.fluid, aspectRatio: 1 }} className="event-list-item__image"/>
					</div>
				</div>
			</ComponentAnimation>
		</div>
	)
}